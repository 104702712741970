.pagination_main_box{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    gap: 10px;
    padding: 20px;
}

.pagination_item_box_unactive{
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: #727272;
}

.pagination_item_box_active{
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EB6E1F;
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
    color: white;
}

.pagination_item_box_unactive:hover{
    background-color: #EB6E1F;
    color: white;
}