:root {
  --doc-height: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.App {
  text-align: center;
  height: 100vh;
  /* overflow-y: auto;
  overflow-x: hidden; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navigation-div {
  width: 100vw;
  /* height: calc(100vh - 81px); */
}

.navigation-div .k-drawer-item {
  display: block;
  margin-bottom: 8px;
}

.navigation-div .k-widget.k-drawer.k-drawer-start,
.navigation-div .k-drawer-wrapper {
  height: 100%;
  width: 260px !important;
  box-shadow: 0px 4px 30px 3px rgba(21, 53, 120, 0.02), 0px 4px 20px 6px rgba(21, 53, 120, 0.05), 0px 2px 5px rgba(21, 53, 120, 0.1);
}

.navigation-div .k-widget.k-drawer.k-drawer-start {
  height: calc(100vh - 81px);
  flex-basis: 260px !important;
  box-shadow: 0px 4px 30px 3px rgba(21, 53, 120, 0.02), 0px 4px 20px 6px rgba(21, 53, 120, 0.05), 0px 2px 5px rgba(21, 53, 120, 0.1);
}

/* .navigation-div .k-drawer-items,
.navigation-div .k-drawer-items ul {
  padding-left: 10px;
  padding-right: 10px;
} */

.navigation-div .k-drawer-item.k-selected,
.navigation-div .k-drawer-item.k-selected:hover {
  color: #ffffff;
  background-color: #eb7930;
  /* border-radius: 8px; */
}

.navigation-div .k-drawer-item {
  /* border-radius: 8px; */
  padding: 0px;
  /* height: 60px; */
}

.navigation-div .item-descr-wrap {
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* color: #776F6A; */
}

.navigation-div .navigation_items_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 24px;
  gap: 10px;
  /* background: #EB7930; */
}

.navigation-div .k-icon.k-i-grid-layout {
  width: 24px;
}

.cereal-title {
  margin-left: 26px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

/* custom */

.custom-toolbar {
  width: 100%;
  background-color: white;
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 1px;
  color: #656565;
  text-align: left;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: space-between;
}

.custom_toolbar_profile {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 17px;
}

.profile_setting {
  cursor: pointer;
}

span.k-bottom-nav-item.k-selected {
  color: #ffffff !important;
  background-color: #eb7930 !important;
  border-radius: 8px;
}

span.k-bottom-nav-item {
  border-radius: 8px;
}

.mobile-content {
  height: calc(100vh - 57px);
  overflow-y: auto;
  overflow-x: hidden;
}

@font-face {
  font-family: "Montserrat-Bold";
  font-weight: 700;
  src: local("Montserrat-Bold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  font-weight: 600;
  src: local("Montserrat-SemiBold"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  font-weight: 500;
  src: local("Montserrat-Medium"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

body {
  overflow-y: hidden;
}

.nearbyButtonGroup button {
  border-radius: 8px;
  color: #d12d2f;
}

.nearbyButtonGroup button.k-selected {
  background: #d12d2f;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  color: #ffffff;
}

.k-bottom-nav-item-text {
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  padding-top: 5px;
}

.k-bottom-nav-item-icon {
  width: 24px;
}

.k-drawer-wrapper {
  /* margin-top: 48px; */
}

.Audit .k-window .k-dialog {
  max-height: 580px;
}