.ECM_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 82px;
  padding: 0px 26px;
  background: #ffffff;
}

.ECM_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.ECM_toolbar_child_box {
  display: flex;
  gap: 8px;
}

.ECM_toolbar_add_button{
  display: flex;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 40px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #eb7930;
  cursor: pointer;
}

.ECM_toolbar_filter_button {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ECM_toolbar_add_button {
  display: flex;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 40px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #eb7930;
  cursor: pointer;
}

.ECM_selection_bar {
  display: flex;
  background: #ffffff;
  height: 82px;
  align-items: center;
  gap: 32px;
  padding: 0px 26px;
}

.ECM_form_toolbar {
  display: flex;
  align-items: center;
  height: 64px;
  background: #ffffff;
  gap: 16px;
  padding: 0px 8px;
  box-shadow: 0px 4px 4px 0px #00000005;
}

.ECM_form_toolbar_back_icon {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.ECM_form_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.ECM_form_input_editable {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
  width: 100% !important;
  border: 1px solid #cccccc I !important;
  border-radius: 8px !important;
  opacity: 1;
}

.ECM_create_main_box{
    margin: 0px 15%;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.ECM_create_editable_main_box{
    flex: 1;
    text-align: left;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.ECM_create_editable_title{
    font-family: "Montserrat-Bold";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
}

.ECM_create_scroll_box{
    margin: 30px 0 20px;
    height: calc(100vh - 276px);
    overflow-y: scroll;
    background: white;
    padding-bottom: 20px;
}

.details-radio {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #ccc !important;
    margin-right: 15px;
}

.k-radio:checked, .k-radio.k-checked {
    border-color: #2B63CA;
    color: white;
    background-color:#2B63CA;
}

.k-radio:checked:focus, .k-radio.k-checked.k-focus {
    box-shadow: unset;
}

.cctvDatePicker{
    /* background: #EB6E1F;  */
    /* color: white; */
    /* border-radius: 100px; */
    line-height: 18px;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    height: 32px;
    padding: 0px 10px;
    /* margin-top: 10px; */
    border-radius: 11px;
    background: white;
    border-color: #E4E1DE;
}

.remark-textarea {
    height: auto !important;
    background: #fff !important;
    border: 1px solid #E4E1DE !important;
    border-radius: 8px !important;
}

.ECM_form_footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 305px);
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 22px;
  box-shadow: 0px -1px 0px #dddddd;
}

.ECM_form_footer_child_box {
  display: flex;
  text-align: right;
  gap: 12px;
}

.ECM_form_footer_audit_trail {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #eb7930;
  padding: 15px 23px;
  cursor: pointer;
}

.ECM_form_footer_cancel {
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eb7930;
  padding: 15px 60px;
  cursor: pointer;
}

.ECM_form_footer_save {
  background: #eb7930;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 15px 60px;
  cursor: pointer;
}

.ECM_form_footer_save.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.ECM_error_text{
  font-family: "Montserrat-Medium";
    font-style: italic;
    font-size: 10px;
    line-height: 18px;
    color: red;
    margin-top: 5px;
}

.ECM_form_toolbar {
  display: flex;
  align-items: center;
  height: 64px;
  background: #ffffff;
  gap: 16px;
  padding: 0px 8px;
}

.ECM_form_toolbar_back_icon {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.ECM_form_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.ECM_audit_trail_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #776f6a;
  text-align: left;
  margin: 30px 16px 22px;
}

.ECM_detail_main_box_desc {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}