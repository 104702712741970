.login-blk {
  overflow-y: scroll;
  max-height: 100vh;
}

.breaker {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 34px 0px;
}

.breaker span {
  color: #eb7930;
  text-align: center;
  font-family: "Montserrat-SemiBold";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.breaker-dot-left {
  /* border: 1px solid #000000; */
  height: 1px;
  margin-right: 16px;
  width: calc(50% - 28px);
  background: #aeb4be;
}

.breaker-dot-right {
  /* border: 1px solid #000000; */
  height: 1px;
  margin-left: 16px;
  width: calc(50% - 28px);
  background: #aeb4be;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px;
  position: absolute;
  box-sizing: border-box;
  z-index: 1;
  top: 50px;
  right: 10px;
  transform: translateY(-50%);
}

.k-input-solid {
  height: 48px !important;
}
.k-floating-label-container.k-empty > .k-label {
  top: 50% !important;
  left: 9px !important;
}
.input_label {
  text-align: left;
  margin: 0px 0px 8px 0px;
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}

.Button_group {
  display: flex;
  justify-content: center;
  gap: 0.3%;
  padding: 0px 16px;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 27px;
}

.blkConfirmOTP,
.blkChangePassword {
  padding: 0px 16px;
  margin-top: 40px;
}

.Button_change_password,
.Button_change_password:hover {
  width: 100%;
  height: 48px;
  border: 1px solid #eb6e1f !important;
  background: #eb6e1f !important;
  color: white !important;
  border-radius: 100px !important;
  cursor: pointer;
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: 32px;
}

.Confirm_Button_group {
  display: flex;
  justify-content: center;
  gap: 2%;
  margin-top: 32px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 27px;
}

.Button_confirm,
.Button_confirm:hover {
  width: 49%;
  height: 48px;
  border: 1px solid #eb6e1f !important;
  background: #eb6e1f !important;
  color: white !important;
  border-radius: 100px !important;
  cursor: pointer;
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.Button_cancel,
.Button_cancel:hover {
  width: 49%;
  height: 48px;
  border: 1px solid #eb6e1f !important;
  background: white !important;
  color: #eb6e1f !important;
  border-radius: 100px !important;
  cursor: pointer;
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.Button_submit_blk {
  margin-top: 32px;
  padding: 0px 16px;
}

.Button_submit {
  width: 100%;
  height: 48px;
  border: 1px solid #eb6e1f !important;
  background: #eb6e1f !important;
  color: white !important;
  border-radius: 100px !important;
  cursor: pointer;
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.otp-parent {
  display: flex;
  flex-flow: row wrap;
  gap: 1%;
}

.otp-input {
  width: 10% !important;
  height: 12px !important;
  padding: 2%;
  border-radius: 8px !important;
  border: 1px solid #e4e1de !important;
  background: #fff !important;
}

.blkRegister {
  border-radius: 8px;
  background: #efefef;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.blkRegister p {
  color: #000;
  text-align: center;
  /* Body/Text */
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin: 0px;
}

.Button_register {
  width: 100%;
  border-radius: 100px;
  height: 48px;
  border: 1px solid #2b63ca;
  background: #2b63ca;
  color: white;
  cursor: pointer;
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  /* min-width: 400px; */
}

.Button_adfs,
.Button_adfs:hover {
  width: 100%;
  border-radius: 100px !important;
  height: 48px !important;
  border: 1px solid #eb6e1f !important;
  background: white !important;
  color: #eb6e1f !important;
  cursor: pointer;
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-bottom: 34px;
  /* min-width: 400px; */
}

.login_media_screen,
.forgot_media_screen {
  width: 40%;
}

.forgot_password {
  margin-top: 24px;
  margin-bottom: 32px;
  cursor: pointer;
  color: #eb6e1f;
  text-decoration: underline;
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  padding-left: 16px;
}

@media (max-width: 769px) {
  .login_media_screen,
  .forgot_media_screen {
    width: 100%;
  }
}

@media (max-width: 426px) {
  .Button_submit,
  .Button_register,
  .Button_adfs {
    width: 100%;
    margin-bottom: 16px;
  }
  .Button_group {
    flex-direction: column;
  }
}

.register_header_box {
  display: flex;
  justify-content: center;
  height: 64px;
  align-items: center;
}

.register_header_sec_box {
  display: flex;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 2%);
  width: 48%;
}

.register_back_icon {
  width: 40px;
  height: 40px;
  align-items: center;
  display: flex;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 12%);
  justify-content: center;
  border-radius: 8px;
  border: 1px solid #efefef;
  margin-left: 8px;
}

.register_page_title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96%;
}

.register_header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
}

.register_title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 32px;
  text-align: left;
}

.confirm_pass_error {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d12d2f;
  text-align: left;
  padding: 8px 0px 0px 0px;
}

.error_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d12d2f;
  /* text-align: left; */
  padding: 8px 0px 0px 0px;
}

.divider {
  /* height: 1px; */
  border: 1px solid #e4e1de;
  background-color: #e4e1de;
}

.Register_dropdown {
  background: #ffffff !important;
  border: 1px solid #e4e1de !important;
  border-radius: 8px !important;
  height: 48px !important;
}

.Profile_main_box {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 40px;
  background: #ffffff;
  overflow-y: scroll;
  height: calc(100vh - 163px);
}

.Profile_main_box_image {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 100%;
}

.Profile_add_img_icon {
  background: #eb6e1f;
  border: 2px solid #ffffff;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: -14px;
  right: 38%;
  cursor: pointer;
}

.Profile_detail_main {
  width: 100%;
  max-width: 600px;
}

.Profile_detail_main_box {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 6px 10px;
  border-bottom: 1px solid #efefef;
}

.Profile_detail_main_box_title {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #776f6a;
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Profile_detail_main_box_desc {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Profile_edit_button {
  width: 92%;
  max-width: 600px;
  margin: 16px;
  background: #eb7930;
  border-radius: 100px;
  min-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.Profile_detail_main_box_desc_input {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
  width: 50% !important;
}

@media (max-width: 769px) {
  .register_header_sec_box {
    width: 100%;
    align-items: center;
    height: 100%;
  }
  .register_title {
    margin-top: 16px;
  }
}

@media (min-width: 769px) {
  .register_header_box {
    display: none;
  }
  .register_title {
    margin-top: 25px;
  }
}

.Forgot_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-align: left;
  margin-top: 40px;
}

.forgot_detail_box {
  margin-top: 32px;
  margin-bottom: 32px;
}

.otp_label {
  color: "#000";
  font-family: "Montserrat";
  font-size: "14px";
  font-style: "normal";
  font-weight: "500";
  line-height: "18px";
  text-align: "left";
  margin-bottom: "32px";
}

.otp_resend_link {
  color: #2b63ca;
  font-family: "Montserrat";
  font-size: "14px";
  font-style: "normal";
  text-decoration: underline;
  font-weight: "500";
  line-height: "18px";
  text-align: "left";
  cursor: pointer;
}
