.logout_button {
  color: #eb7930 !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  padding: 7px 15px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.logout_button p:hover {
  color: #ffa800;
}

.logout_popup_box {
  margin-top: 40px;
  padding: 20px 50px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #000000;
  line-height: 22px;
}

.logout_popup_action_box {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.logout_popup_action_button {
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  /* color: #EB7930; */
  padding: 15px 42px;
  cursor: pointer;
}

.k-dialog {
  border-radius: 8px !important;
}

.navigation_items_box.selected {
  background-color: #efefef;
}

.navigation_sub.selected{
  background-color: #efefef;
}

.navigation_sub .k-drawer-item:focus{
  background-color: inherit;
  box-shadow: none;
}

.navigation_sub_item_box{
  display: flex;
    min-height: 50px;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px 20px 40px;
    text-align: left;
    font-family: 'Montserrat-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.navigation_sub_item_box .selected{
  color: #eb7930;
}