.work_activity_main_box{
    margin: 40px 20px 0px;
}

.work_activity_title{
    text-align: left;
    margin-bottom: 20px;
    font-family: 'Montserrat-Bold';
    font-style: normal;
    font-size: 18px;
    line-height: 18px;
    height: 40px;
    display: flex;
    align-items: center;
}

.work_activity_child_box{
    height: calc(100vh - 260px);
    overflow-y: scroll;
}

.work_activity_input_box{
    display: flex;
    gap: 20px;
    height: 60px;
    align-items: center;
}

.work_activity_delete_icon{
    width: 45px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #EB7930;
    border-radius: 8px;
    background: white;
    cursor: pointer;
}

.work_activity_footer_box{
    position: sticky;
    bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
    background: white;
    margin: 0px -20px;
    width: calc(100% + 20px);
    justify-content: end;
    padding: 0px 20px 0px 0px;
    gap: 20px;
    box-shadow: 0px -1px 0px #dddddd;
}

.work_activity_footer_button{
    width: 150px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 100px;
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-size: 14px;
    cursor: pointer;
}

.work_activity_footer_add_button{
    border-color: #eb6e1f;
    color: #eb6e1f;
}

.work_activity_footer_edit_button{
    border-color: #eb6e1f;
    background-color: #eb6e1f;
    color: white;
}

.work_activity_child_box::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.work_activity_child_box::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.work_activity_child_box::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.work_activity_child_box::-webkit-scrollbar-thumb:hover {
    background: grey;
}