.OHS_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 82px;
  padding: 0px 26px;
  background: #ffffff;
}

.OHS_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.form-upload-btn {
    display: flex !important;
    flex-direction: row;
    color: #EB6E1F;
    color: #EB6E1F !important;
    gap: 5px;
    height: 16px;
    justify-content: center;
    vertical-align: top;
}

.OHS_Upload_main_box{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    height: 50px;
    background: white;
    border: 1px solid #EB7930;
    border-radius: 10px;
    margin: 10px;
    justify-content: space-between;
    cursor: pointer;
}

.OHS_popup_main_box {
    position: absolute;
    left: -170px;
    width: 220px;
    top: -45px;
}

.OHS_popup_text_box {
    padding: 10px;
    border-radius: 10px;
    background: white;
    font-family: 'Montserrat-Medium';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid #EB7930;
}

.OHS_popup_tips {
    border: 10px solid transparent;
    border-top-color:#EB7930 !important;
    border-bottom: none;
    width: 2px;
    margin: 0px 175px;
}