table {
  width: 100%;
  text-align: left;
  border: 0;
  background: #efefef;
  border-collapse: collapse;
}

th,
td {
  padding: 0px 16px;
}

th {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #727272;
}

td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #776f6a;
}

#table {
  max-width: calc(100vw - 260px);
  overflow-x: auto;
}

.Table_header {
  gap: 10px;
  display: flex;
  align-items: center;
  height: 40px;
}

.Table_button {
  background: #eb7930;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 48px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  justify-content: center;
  max-width: 155px;
  cursor: pointer;
}

.TableHandle {
  height: 60px;
  background: white;
  cursor: pointer;
}

.TableHandle:hover {
  background-color: #fafafa;
}

/* width */
#table::-webkit-scrollbar {
  width: 10px;
}

/* Track */
#table::-webkit-scrollbar-track {
  border-radius: 5px;
}

/* Handle */
#table::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 5px;
}

/* Handle on hover */
#table::-webkit-scrollbar-thumb:hover {
  background: grey;
}


.Table_header_box{
  display: flex;
  align-items: center;
  gap: 20px;
  text-align: left;
}

.Table_flex{
  flex: 1 1;
  overflow: hidden;
}

.Table_text_overflow{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Table_header_text{
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #727272;
}

.Table_body_text{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #776f6a;
}

.Table_header{
  background-color: rgb(239, 239, 239);
  height: 40px;
  padding: 0 28px 0 20px;
}

.Table_body{
  background-color: white;
  height: 60px;
  padding: 0 20px;
  cursor: pointer;
}

.Table_body:hover {
  background-color: #fafafa;
}

.scrolledDIV::-webkit-scrollbar {
    width: 8px;
}

/* Track */
.scrolledDIV::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    border-radius: 5px;
}

/* Handle */
.scrolledDIV::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 5px;
}

/* Handle on hover */
.scrolledDIV::-webkit-scrollbar-thumb:hover {
    background: grey;
}