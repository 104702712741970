.timePicker,
.datePicker {
  border: 1px solid #e4e1de !important;
  padding: 0px 7px !important;
  border-radius: 8px !important;
}
.react-datepicker__time {
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}
.float-datePicker {
  position: absolute;
  z-index: 100;
}

.sitrepDatePicker1{
  background: #EB6E1F;
  color: white;
  line-height: 18px;
  font-size: 12px;
  font-family: 'Montserrat-Bold';
  height: 32px;
  border-radius: 100px;
  padding: 0px 10px;
}