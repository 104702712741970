.CCTV_form_toolbar {
  display: flex;
  align-items: center;
  height: 64px;
  background: #ffffff;
  gap: 16px;
  padding: 0px 8px;
  box-shadow: 0px 4px 4px 0px #00000005;
}

.CCTV_form_toolbar_back_icon {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.CCTV_form_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.CCTV_form_input_editable {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
  width: 100% !important;
  border: 1px solid #cccccc I !important;
  border-radius: 8px !important;
  opacity: 1;
}

.CCTV_create_main_box{
    margin: 0px 15%;
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.CCTV_create_editable_main_box{
    flex: 1;
    text-align: left;
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.CCTV_create_editable_title{
    font-family: "Montserrat-Bold";
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
}

.CCTV_create_scroll_box{
    margin: 30px 0 20px;
    height: calc(100vh - 276px);
    overflow-y: scroll;
    background: white;
    padding-bottom: 20px;
}

.details-radio {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #ccc !important;
    margin-right: 15px;
}

.k-radio:checked, .k-radio.k-checked {
    border-color: #2B63CA;
    color: white;
    background-color:#2B63CA;
}

.k-radio:checked:focus, .k-radio.k-checked.k-focus {
    box-shadow: unset;
}

.cctvDatePicker{
    /* background: #EB6E1F;  */
    /* color: white; */
    /* border-radius: 100px; */
    line-height: 18px;
    font-size: 14px;
    font-family: 'Montserrat-Medium';
    height: 32px;
    padding: 0px 10px;
    /* margin-top: 10px; */
    border-radius: 11px;
    background: white;
    border-color: #E4E1DE;
}

.remark-textarea {
    height: auto !important;
    background: #fff !important;
    border: 1px solid #E4E1DE !important;
    border-radius: 8px !important;
}

.CCTV_form_footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 305px);
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 22px;
  box-shadow: 0px -1px 0px #dddddd;
}

.CCTV_form_footer_child_box {
  display: flex;
  text-align: right;
  gap: 12px;
}

.CCTV_form_footer_audit_trail {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #eb7930;
  padding: 15px 23px;
  cursor: pointer;
}

.CCTV_form_footer_cancel {
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eb7930;
  padding: 15px 60px;
  cursor: pointer;
}

.CCTV_form_footer_save {
  background: #eb7930;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 15px 60px;
  cursor: pointer;
}

.CCTV_form_footer_save.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.CCTV_error_text{
  font-family: "Montserrat-Medium";
    font-style: italic;
    font-size: 10px;
    line-height: 18px;
    color: red;
    margin-top: 5px;
}

.CCTV_form_toolbar {
  display: flex;
  align-items: center;
  height: 64px;
  background: #ffffff;
  gap: 16px;
  padding: 0px 8px;
}

.CCTV_form_toolbar_back_icon {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.CCTV_form_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.CCTV_audit_trail_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #776f6a;
  text-align: left;
  margin: 30px 16px 22px;
}

.CCTV_detail_main_box_desc {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.CCTV-filter-width_resize {
  /* max-width: 440px; */
  max-width: 500px;
}

.CCTV-filter-width_resize .k-input-button .k-icon.k-i-calendar:before {
  content: none !important;
}

.CCTV-filter-width_resize .k-input-button .k-icon.k-i-calendar {
  background-image: url("/src/assets/images/Icon/calendar-icon.svg") !important;
  background-repeat: no-repeat !important;
}

.CCTV-filter-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 30px 20px 20px 20px;
}

.CCTV-filter-sub-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0px 0px 0px 20px;
}

.CCTV-filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
}

.CCTV-filter-item {
  width: 50%;
  height: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  /* text-transform: uppercase; */
}

.CCTV-filter-item .k-checkbox {
  width: 24px !important;
  height: 24px !important;
  margin-right: 16px;
}

.CCTV_Filter_button_main_box{
  display: flex;
  gap: 20px;
  margin: 20px;
}

.CCTV_Filter_clear_button{
  flex: 1;
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #EB7930;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid #EB7930;
    border-radius: 100px;
    cursor: pointer;
}

.CCTV_Filter_apply_button{
  flex: 1;
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    background: #EB7930;
    color: white;
    display: flex;
    align-items: center;
    height: 48px;
    justify-content: center;
    border-radius: 100px;
    cursor: pointer;
}

.CCTV_Delete_confirm_title{
    font-family: "Montserrat-Bold";
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.CCTV_Delete_confirm_text{
  font-family: "Montserrat-Medium";
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 20px;
}

.CCTV_last_modified{
font-family: "Montserrat-Medium";
font-size: 14px;
font-weight: 500;
line-height: 18px;
letter-spacing: 0px;
text-align: left;
}

.CCTV .Table_header_box.Table_header{
  height: 60px;
}

.CCTV .Table_text_overflow.Table_header_text {
    white-space: unset;
}

@media (max-width: 768px) {
  .CCTV-filter-width_resize {
    max-width: none;
    width: 100%;
  }

  .CCTV-filter-box {
    justify-content: space-evenly;
    margin: 20px 60px;
  }
}

@media (max-width: 426px) {
  .CCTV-filter-box {
    justify-content: space-evenly;
    margin: 20px 20px 0px 20px;
  }
}