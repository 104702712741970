.Approval_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 82px;
  padding: 0px 26px;
  background: #ffffff;
}

.Approval_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
}

.Approval_toolbar_child_box {
  display: flex;
  gap: 8px;
}

.Approval_toolbar_add_button {
  display: flex;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #eb7930;
  border-radius: 100px;
  height: 40px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #eb7930;
  cursor: pointer;
}

.Approval_toolbar_filter_button {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Approval_selection_bar {
  display: flex;
  background: #ffffff;
  height: 82px;
  align-items: center;
  gap: 32px;
  padding: 0px 26px;
}

.Approval_selection_bar_active {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #eb7930;
  border-radius: 100px;
  padding: 7px 12px;
}

.Approval_selection_bar_unactive {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #5c5146;
}

.Approval_main_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  height: calc(100vh - 307px);
  overflow-y: scroll;
}

.Approval_main_box_image {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 100%;
}

.Approval_add_img_icon {
  background: #eb6e1f;
  border: 2px solid #ffffff;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: -14px;
  right: 38%;
  cursor: pointer;
}

.Approval_detail_main {
  width: 100%;
  width: 70%;
  margin-bottom: 60px;
}

.Approval_detail_main_box {
  display: flex;
  justify-content: space-between;
  text-align: left;
  padding: 12px 10px;
  flex-direction: column;
  width: 97%;
  gap: 8px;
}

.Approval_detail_main_box_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #000;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Approval_detail_main_box_desc {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.Approval_edit_button {
  width: 92%;
  max-width: 600px;
  margin: 16px;
  background: #eb7930;
  border-radius: 100px;
  min-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.Approval_detail_main_box_desc_input {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
  width: 100% !important;
  border: 1px solid #e4e1de;
  border-radius: 8px;
}

.Approval_detail_main_box_desc_input.disabled {
  opacity: 0.5;
}

.k-input-solid {
  height: 48px !important;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.Approval_form_toolbar {
  display: flex;
  align-items: center;
  height: 64px;
  background: #ffffff;
  gap: 16px;
  padding: 0px 8px;
}

.Approval_form_toolbar_back_icon {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.Approval_form_toolbar_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.Approval_form_sec_toolbar {
  display: flex;
  height: 82px;
  background: #ffffff;
  align-items: center;
  gap: 20px;
  padding: 0px 26px;
}

.Approval_form_sec_toolbar_item_active {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 7px 12px;
  background: #eb7930;
  border-radius: 100px;
  cursor: pointer;
}

.Approval_form_sec_toolbar_item_unactive {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #5c5146;
  padding: 7px 12px;
  cursor: pointer;
}

.Usermanagement_form_account_main_box {
  background: rgb(255, 255, 255);
  padding-top: 32px;
  width: 70%;
  margin: auto;
}

.Approval_form_account_input_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  text-align: left;
}

.Approval_form_account_input {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
  width: 100% !important;
  border: 1px solid #cccccc I !important;
  border-radius: 8px !important;
  background: #f2f2f2 !important;
  opacity: 1 !important;
}

.Approval_form_account_input_editable {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
  width: 100% !important;
  border: 1px solid #cccccc I !important;
  border-radius: 8px !important;
  opacity: 1 !important;
}

.Approval_form_account_check_title {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
}

.Approval_form_footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 305px);
  height: 80px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 22px;
  box-shadow: 0px -1px 0px #dddddd;
}

.Approval_form_footer_archived {
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eb7930;
  padding: 15px 23px;
  cursor: pointer;
}

.Approval_form_footer_approve {
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eb6e1f;
  padding: 15px 23px;
  cursor: pointer;
  min-width: 100px;
  justify-content: center;
}

.Approval_form_footer_rejected {
  background: #ffffff;
  border: 1px solid #d12d2f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #d12d2f;
  padding: 15px 23px;
  cursor: pointer;
  min-width: 100px;
  justify-content: center;
}

.Approval_form_footer_child_box {
  display: flex;
  text-align: right;
  gap: 12px;
}

.Approval_form_footer_audit_trail {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;
  color: #eb7930;
  padding: 15px 23px;
  cursor: pointer;
}

.Approval_form_footer_cancel {
  background: #ffffff;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #eb7930;
  padding: 15px 60px;
  cursor: pointer;
}

.Approval_form_footer_save {
  background: #eb7930;
  border: 1px solid #eb6e1f;
  border-radius: 100px;
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 15px 60px;
  cursor: pointer;
}

.Approval_form_footer_save.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.Approval_audit_trail_title {
  font-family: "Montserrat-Bold";
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #776f6a;
  text-align: left;
  margin: 30px 16px 22px;
}

.Approval_detail_main_box span.k-input-value-text {
  font-family: "Montserrat-Medium" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000 !important;
}

.Approval_detail_main_box .k-input-button .k-icon.k-i-caret-alt-down:before {
  content: none !important;
}

.Approval_detail_main_box .k-input-button .k-icon.k-i-caret-alt-down {
  background-image: url("/src/assets/images/Icon/dropdown-arrow.svg") !important;
  background-repeat: no-repeat !important;
  margin-top: 15px !important;
}

.Approval_detail_main_box .k-dropdownlist {
  background: #ffffff !important;
  border: 1px solid #e4e1de !important;
  border-radius: 8px !important;
  height: 48px !important;
}

.Approval_detail_main_box .k-textarea {
  height: 115px !important;
}

.Approval_package_box {
  display: inline-block;
  background: #2b63ca;
  border-radius: 100px;
  padding: 9px 14px;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 5px;
}

.Approval_package_sec_box {
  font-family: "Montserrat-Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
  display: inline-block;
}

.container {
  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  border-radius: 100px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 24px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #e4e1de;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  /* background-color: #ccc; */
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2b63ca;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.approval-filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
}

.approval-filter-item {
  width: 50%;
  height: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  /* text-transform: uppercase; */
}

.approval-filter-item .k-checkbox {
  width: 24px !important;
  height: 24px !important;
  margin-right: 16px;
}

.approval-filter-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin: 30px 20px 20px 20px;
}

.approval-filter-sub-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0px 0px 0px 20px;
}

.approval-filter-width_resize {
  /* max-width: 440px; */
  max-width: 500px;
}

.approval-filter-button-group {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  gap: 20px;
  margin: 20px 20px 30px 20px;
}

.approval-cancel-button {
  width: 44%;
  /* margin: 10px 20px 10px 20px; */
  height: 48px;
  color: #eb7930 !important;
  border: 1px solid #eb7930 !important;
  border-radius: 100px !important;
  background-color: #ffffff !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.approval-filter-button {
  width: 44%;
  /* margin: 10px 20px 10px 20px; */
  height: 48px;
  background: #eb7930 !important;
  border: 1px solid #eb7930 !important;
  border-radius: 100px !important;
  color: #ffffff !important;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.approval-filter-dropdown-value {
  background: #ffffff !important;
  border: 1px solid #e4e1de !important;
  border-radius: 8px;
  height: 48px;
}

.approval-filter-dropdown {
  margin: 10px 20px 20px 20px;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #000000;
}

.approval-filter-dropdown .k-i-caret-alt-down {
  color: #eb7930 !important;
}

.k-animation-container {
  /* z-index: 10003 !important; */
}

.approval-filter-date-wrapper {
  margin: 10px 20px 20px 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.approval-filter-date-wrapper > div {
  width: 49%;
}

.approval-filter-date-label {
  font-family: "Montserrat-SemiBold" !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
}

.approval-filter-date-input {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  margin-top: 10px !important;
}

.approval-filter-dropdown-value
  .k-input-button
  .k-icon.k-i-caret-alt-down:before {
  content: none !important;
}

.approval-filter-dropdown-value .k-input-button .k-icon.k-i-caret-alt-down {
  background-image: url("/src/assets/images/Icon/dropdown-arrow.svg") !important;
  background-repeat: no-repeat !important;
  margin-top: 15px !important;
}

.approval-filter-date-input .k-input-button {
  background-color: #ffffff;
  border: 0px !important;
  background-image: none !important;
}

.approval-filter-width_resize .k-input-button .k-icon.k-i-calendar:before {
  content: none !important;
}

.approval-filter-width_resize .k-input-button .k-icon.k-i-calendar {
  background-image: url("/src/assets/images/Icon/calendar-icon.svg") !important;
  background-repeat: no-repeat !important;
}

@media (max-width: 768px) {
  .approval-filter-button {
    max-width: none;
    width: 92%;
  }

  .approval-filter-width_resize {
    max-width: none;
    width: 100%;
  }
  .k-dialog-wrapper .k-dialog {
    position: absolute !important;
    bottom: 0;
    border-radius: 8px 8px 0px 0px;
    width: 100%;
  }
  .approval-filter-box {
    justify-content: space-evenly;
    margin: 20px 60px;
  }
}

@media (max-width: 426px) {
  .approval-filter-box {
    justify-content: space-evenly;
    margin: 20px 20px 0px 20px;
  }
}

.k-animation-container {
  z-index: 10003 !important;
}
