.timePicker,
.datePicker {
  border: 1px solid #e4e1de !important;
  padding: 0px 7px !important;
  border-radius: 8px !important;
}
.react-datepicker__time {
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background-color: #d9d9d9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}
.float-datePicker {
  position: absolute;
  z-index: 100;
}

.sitrepDatePicker{
  /* background: #EB6E1F; */
  /* color: white; */
  /* border-radius: 100px; */
  line-height: 18px;
  font-size: 12px;
  font-family: 'Montserrat-Bold';
  height: 32px;
  padding: 0px 10px;
  margin-top: 10px;
  border-radius: 11px;
  background: white;
  color: #EB6E1F;
  border-color: #EB6E1F;
}

.sitrepDatePicker:hover{
  border: 1px solid #EB6E1F
}

.color_change{
    filter: invert(48%) sepia(72%) saturate(691%) hue-rotate(342deg) brightness(93%) contrast(98%);
}